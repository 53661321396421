import { AiFillAppstore } from 'react-icons/ai'
import { FaCss3 } from 'react-icons/fa6'
import { IoLogoJavascript } from 'react-icons/io'
import { RiReactjsLine } from 'react-icons/ri'

const data = [
  {
    id: 1,
    icon: <AiFillAppstore />,
    title: 'UI/UX Principles',
    desc: 'Maria develops frontend interfaces that not only look good but also prioritize a seamless and enjoyable experience for the end user.',
  },
  {
    id: 2,
    icon: <FaCss3 />,
    title: 'HTML/CSS/Sass/Tailwind',
    desc: 'Maria is able to build and style responsive web pages effectively, combining structure, efficiency, and visual appeal.',
  },
  {
    id: 3,
    icon: <IoLogoJavascript />,
    title: 'JavaScript',
    desc: "Maria's proficiency in JavaScript showcases her ability to create dynamic and interactive web applications, enhancing the overall user experience.",
  },
  {
    id: 4,
    icon: <RiReactjsLine />,
    title: 'React',
    desc: 'Maria has the ability to build interactive, scalable, and maintainable user interfaces, making her a valuable asset in modern frontend development.',
  },
]

export default data
