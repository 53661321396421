

import Logo from '../../assets/logo-mini.png';

const data = [
    {id: 1, link: '#portfolio', title: 'Portfolio'},
    {id: 2, link: '#', title:Logo},
    {id: 3, link: '#skills', title: 'Skills'},
    {id: 4, link: '#about', title: 'About'},
    {id: 5, link: '#contact', title: 'Contact'}
    ]

export default data;